@import '../../index.scss';

label {
     background-color: $slider-ball;
     color: $wheat-text;
}

.checkbox-simple-container {
     width: 100%;
     height: 100%;
     display: flex;
     margin-left: 10px;

     .checkbox-btn-label {
          margin: 0 0;
          font-size: 20px;
          // THESE ARE THE PROPERTIES THAT ARE CAUSING THE HOVER DIV TO SHOW BELOW THE CHECKBOX LABELS
          // position: relative;
          // left: 0%;
          // top: 2px;
     }

}

.hidden {
     display: none; 
}

.checkbox {  
     width: 1.3em;
     height: 1.3em;
     border-radius: 50%;
     border: 1px solid #ddd;
     position: relative;
     left: -120px;
     bottom: 18px;
     appearance: none;
     -webkit-appearance: none;
     outline: none;
     cursor: pointer;
}

.checkbox:checked {
     border: 1px solid $wheat-text;
     background-color: rgb(5, 5, 194);
}