// alternate nice dark background color rgb(21,28,50)
$primary: #1b2d4b; // background
$primary-dark: #1d222d; // background
$primary-darker: #31476b; // background
$white: rgb(255, 255, 255); // text
$hoverBackground: rgb(255, 255, 255);
$black: black;
$blackblack: black;
$grey: grey;
$wheat-text: #f5deb3;
$translucent-white: rgba(255, 255, 255, 0.099); //next/back button hover
$slider-ball: #73231c;
$slider-background-light: #be5d524b;
$slider-background-dark: rgb(59, 45, 61);
$rain-color: rgba(255, 255, 255, 0.627);
$secondary: #fc5b4b;
$instructionsBackground: rgba(255, 255, 255, 0.233);
$tileBackground: rgba(44,72,81,0.3);
$cardBackground:rgba(255, 255, 255, 0.685);
$cardBackgrounHover: rgb(170, 175, 187);
$blackLight: rgba(0, 0, 0, 0.784);
$lightBlue: rgba(13, 60, 203, 0.812);
$darkBlue: rgba(7, 48, 171, 0.895);
$darkRed: rgba(136, 26, 26, 0.989);
$darkGren: rgba(7, 124, 56, 0.989);
$sideBarWhite: rgba(255, 255, 255, 0.355);
$sideBarBorder: rgba(255, 255, 255, 0.607);
$red: #f32323;
$highlight-blue: rgba(0, 183, 255, 0.458);
$navbarHover: rgb(247, 206, 132);

// // LIGHT MODE COLORS
// $primary-light: #1b2d4b; // background
// $primary-darker-light: #31476b; // background
// $white-light: rgb(255, 255, 255); // text
// $black-light: black;
// $grey-light: grey;
// $wheat-text-light: #f5deb3;
// $translucent-white-light: rgba(255, 255, 255, 0.099); //next/back button hover
// $slider-ball-light: #73231c;
// $slider-background-light-light: #be5d524b;
// $slider-background-dark-light: rgb(59, 45, 61);
// $rain-color-light: rgba(255, 255, 255, 0.627);
// $secondary-light: #fc5b4b;
// $instructionsBackground-light: rgba(255, 255, 255, 0.233);
// $tileBackground-light: rgba(44,72,81,0.3);
// $cardBackground-light:rgba(255, 255, 255, 0.685);
// $cardBackgrounHover-light: rgb(170, 175, 187);
// $blackLight-light: rgba(0, 0, 0, 0.784);
// $lightBlue-light: rgba(13, 60, 203, 0.812);
// $darkBlue-light: rgba(7, 48, 171, 0.895);
// $darkRed-light: rgba(136, 26, 26, 0.989);
// $darkGren-light: rgba(7, 124, 56, 0.989);
// $sideBarWhite-light: rgba(255, 255, 255, 0.355);
// $sideBarBorder-light: rgba(255, 255, 255, 0.607);
// $red-light: #f32323;

// $primary: #f5f5f5; // background
// $primary-darker: #e0e0e0; // background
// $white: rgb(83, 29, 29); // text
// $hoverBackground: rgb(255, 255, 255);
// $black: white;
// $blackblack: black;
// $grey: #b3b3b3;
// $wheat-text: #88602b;
// $translucent-white: rgba(255, 255, 255, 0.2); //next/back button hover
// $slider-ball:  wheat;
// $slider-background-light: #e3e3e3;
// $slider-background-dark: #f0f0f0;
// $rain-color: rgba(0, 0, 0, 0.627);
// $secondary: #fc5b4b;
// $instructionsBackground: rgba(255, 255, 255, 0.8);
// $tileBackground: rgba(255, 255, 255, 0.693);
// $cardBackground:rgba(0, 0, 0, 0.2);
// $cardBackgrounHover: #d9d9d9;
// $blackLight: rgb(83, 29, 29); //scroll bar color
// $lightBlue: rgba(13, 60, 203, 0.5);
// $darkBlue: rgba(7, 48, 171, 0.7);
// $darkRed: rgba(136, 26, 26, 0.9);
// $darkGren: rgba(7, 124, 56, 0.9);
// $sideBarWhite: rgba(255, 255, 255, 0.8);
// $sideBarBorder: rgba(0, 0, 0, 0.2);
// $red: #f32323;


// TRY ORANGE
//nice blue color #151b5ed1
//nice light white to go with above blue rgba(222, 215, 215, 0.595)
// nide green ish color #45736d

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  background-color: $primary;
}

.link-white {
  text-decoration: none;
  color: $white;
}

.link-white-no-padding {
  text-decoration: none;
  color: $white;
}

.link-wheat {
  text-decoration: none;
  color: $wheat-text;
  z-index: 2;
}

.app-container {
  width: 100%;
  height: 100%;
}

h2 {
  position: absolute;
  top: -60px;
  padding: 0;
  margin: 0;
  color: $white;
  font-style: italic;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  h2 {
    top: -90px;
  }

  .validation-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.spaced-out-text {
  letter-spacing: 1px;
  line-height: 30px;  
}

.spaced-out-text-less {
  letter-spacing: 1px;
  line-height: 23px;  
}

.validation-message {
  position: absolute;
  top: -20px;
  color: $red;
  font-weight: 700;
  width: 100%;
  text-align: center;
}

.slider-parent {
  position:relative;
}

.slider-parent-medium {
  height: 23px;
}

.buble{
  position:relative;
  // bottom: 130px;
  // background-color: rgba(211, 179, 179, 0.185);
  // width: 30px;
  padding: 5px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
  color: $white;
  font-size: 32px;
}

.slider-parent input[type=range] {
  height: 34px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 62vw;
  background-color: rgba(0, 0, 255, 0);
}

.slider-parent-medium input[type=range] {
  -webkit-appearance: none;
  width: 32vw;
  background-color: rgba(0, 0, 255, 0);
}

.slider-parent-small input[type=range] {
  height: 34px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 17vw;
  background-color: rgba(0, 0, 255, 0);
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #002200;
  background: $slider-background-light;
  border-radius: 21px;
  border: 1px solid $white;
}
input[type=range]::-webkit-slider-thumb {
  // box-shadow: 3px 3px 3px #00AA00;
  border: 2px solid $white;
  height: 23px;
  width: 23px;
  border-radius: 23px;
  background: $slider-ball;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

.loader-container {
  position: absolute;
  top: 17%;
  left: 63%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100000;
}

// CONTACT US FORM

.about-us-container {
  overflow: hidden;
}

.contact-us-info {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  margin: 100px 0px;
  color: $white;
}

.contact-us-form {
  margin: 0px 7%;

  input {
    margin: 20px;
    width: 200px;
    height: 40px;
  }

  textarea {
    margin: 20px;
    width: 400px;
    height: 100px;
  }

  button {
    margin: 20px;
  }
}

.spaced-out-p {
  font-size: large;
  margin: 0px 100px 50px 30px;
}

// CONTACT US

.contact-us-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

// ABOUT US

.about-us-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 100px auto 100px auto;
  color: $white;
  min-height: 100%;
  max-width: 1100px;
  padding: 0px 50px;
}

.shapes-1 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  background: #3d31dd68;
  border-radius: 150px;
}

.shapes-2 {
  position: relative;
  display: flex;
  margin-left: 25px;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  background: #e76559;;
  border-radius: 50%;
  opacity: .76;
  max-height: 300px;
}

.shapes-3 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  background: #d459e758;;
  border-radius: 5%;
  opacity: .76;
  max-height: 300px;
}


@media only screen and (max-width: 950px) {
  .shapes-1 {
    display: none;
  }

  .shapes-2 {
    display: none;
  }

  .shapes-3 {
    display: none;
  }

  .about-us-container {
    display: grid;
    grid-template-columns: 1fr;
  }

  .contact-us-info {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.curve {
  position: absolute;
  height: 250px;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.curve::before {
  content: '';
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  transform: translate(85%, 60%);
  background-color: hsl(216, 21%, 16%);
}

.curve::after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color: #3d31dd;
  transform: translate(-4%, 40%);
  z-index: -1;
}

// FOOTER 

.footer {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 3;
  min-height: 300px;
  background-color: $primary-dark;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .link-header {
      font-size: 20px;
      font-weight: 200;
  }

  a {
      list-style-type: none;
      color: $white;
      font-size: 16px;
      font-weight: 100
  }

  li {
      margin: 20px;
      list-style-type: none;
      color: $white;
      font-size: 18px;
  }

  ul {
      padding: 0;
  }

  @media only screen and (max-width: 767px) {
      .link-header {
        font-size: 14px;
      }

      li {
          font-size: 10px;
      }
    }

  .subscribe-container {
      border-left: 1px solid $white;
      margin: 10px;
      color: $white;
      font-size: 18px;

      div {
          margin: 20px 0px 20px 20px;
      }

      input {
          height: 30px;
          width: 200px;
          font-size: 18px;
      }
  
      .subscribe-btn {
          border: none;
          padding: 10px;
          margin-left: 20px;
          background: rgb(252,91,75);
          background: linear-gradient(124deg, rgba(252,91,75,1) 0%, rgba(252,91,75,1) 22%, rgba(252,91,75,1) 41%, rgba(252,91,75,0.9584427521008403) 57%, rgba(252,91,75,0.8996192226890756) 74%, rgba(252,91,75,0.8576024159663865) 94%);
      }

  }

}

.thin-text {
  font-weight: 200;
  color: $white;
}

.bold-text {
  font-weight: 600;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 30px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-28 {
  font-size: 28px;
}

.font-30 {
  font-size: 30px;
}

.font-55 {
  font-size: 55px;
}

.font-white {
  color: $white;
}

.margin-10 {
  margin: 10px
}

.margin-15 {
  margin: 10px
}

.margin-20 {
  margin: 20px;
}

.margin-25 {
  margin: 20px;
}

.margin-50 {
  margin: 50px;
}

.margin-75 {
  margin: 50px;
}

.margin-100 {
  margin: 50px;
}

.footer-logo {
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
  color: white;
  margin: 60px 0px 10px;
}

// FAQ

.faqs-container {
  position: relative;
  height: 100%;
  margin: 150px 100px;
}

.faq-header {
  margin-left: 50px;
}

.cf:after {
  clear: both; }

.Collapsible {
  background-color: rgba(255, 255, 255, 0.064); 
  border-radius: 5px;
  margin: 5px;
  color: white;
  font-size: 20px;
  font-weight: 300;
}

.Collapsible__contentInner {
  padding: 20px;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  // border: 1px solid white;
  padding: 10px;
  background: #00ac9e00;
  color: white; }
  .Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms; }
  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.CustomTriggerCSS--open {
  background-color: darkslateblue; }

  // PURCHASING TIPS

  .purchasing-tips-container {
    position: relative;
    height: 100%;
    margin: 100px auto;
    padding: 0 7%;
    color: $white;
    max-width: 1100px;
  }
  
  .purchasing-tips-header {
    margin-left: 50px;
  }

  // TERMS OF USE

  .terms-of-use-info {
    position: relative;
    height: 100%;
    margin: 150px 100px;
    color: $white;
  }
