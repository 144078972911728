@import '../../index.scss';

.header-text {
    h1 {
        position: absolute;
        font-size: 30px;
        font-weight: 200;
        top: 95px;
        margin: 0 10%;
        padding: 0;
        color: $white;
        text-shadow: 1px 1px 10px $blackLight;
    }
}

@media only screen and (max-width: 767px) {
    .header-text {

        h1{
        top: 30px;
        }
    }
}