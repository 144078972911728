@import '../../index.scss';

.slider-message {
    opacity: .7;
    position: absolute;
    top: 105px;
    width: 30px;
    text-align: center;
    margin-left: -20px;
    color: $white;
}

@media only screen and (max-width: 767px) {
  .slider-message {
    opacity: .7;
    position: absolute;
    top: 110px;
    width: 30px;
    text-align: center;
    margin-left: -20px;
    color: $white;
}
}

.simple-slider-label {
    text-align: center;
}

.input-simple-slider {
    width: 80%;
    top: 20px;
    margin: auto 25px;
}

.input-simple-slider::-webkit-slider-thumb {
  position: relative;
  top: 4px;
}
  
  input[type='range']::-webkit-slider-thumb {
    // THESE ARE THE PROPERTIES CAUSING THE HOVER DIV TO SHOW BELOW THE SLIDER THUMBS
    // position: relative;
    // top: 3px;
    box-shadow: 1px 1px 5px black;
  }
