@import '../../index.scss';

.sidebar {
    position: relative;
    width: 260px;
    height: 80vh;
    background-color: $sideBarWhite;
    border-radius: 3px;
    border: 1px solid $sideBarBorder;
    overflow-y: scroll;
    margin-right: -40px;
    z-index: 0;
}

@media only screen and (max-width: 767px) {
    .sidebar {    
      position: relative;
      width: 100%;
      height: 85vh;
      background-color: $sideBarWhite;
      border-radius: 3px;
      border: 1px solid $sideBarBorder;
      overflow-y: scroll;        
    }

    .sidebar-container {
      margin-bottom: 10px;
      width: 100%;
  }
  }

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 0;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $blackLight;
    box-shadow: 0 0 1px $sideBarWhite;
  }

.sidebar-container {
    margin-bottom: 10px;

    h4 {
        font-size: 17px;
        font-weight: 700;
        margin: 15px 0 10px 5px ;
        padding: 0;
    }

}

p {
    margin: 0;
    padding: 0;
}

input[type=text], input[type=number] {
    width: 65px;
    height: 25px;
    border-radius: 3px;
    font-size: 18px;
    font-weight: 500;
    border: none;
    border: 1px solid $white;
}

.flex-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
}

.towing-weight {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
