@import '../../../index.scss';

.instructions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.instructions-component {
    position: relative;
    color: $white;
    margin-top: -50px;

    h1 {
        text-align: center;
        margin-bottom: 40px;
    }

    ul {
        margin: 0 auto;
        padding: 0;
    }

    li {
        margin: 10px;
        font-size: 22px;
        background-color:$instructionsBackground;
        border-radius: 35px;
        padding: 15px;
        text-align: center;
        list-style-type: none;
        box-shadow: 1px 1px 2px $black;
        min-width: 330px;
    }

    @media only screen and (max-width: 767px) {
        li {
          font-size: 16px;
        }
      }
}