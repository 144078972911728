.radio-btn {
    margin: 0 auto;
    font-size: 20px;
    position: relative;
}

.radio-btn-label {
    font-size: 20px;
    margin: 0;
    padding: 0;
}

.radio-btn-simple-container {
    display: block;
    margin: 0 auto;
}

// PREVENT BUTTON/TILE TEXT FROM RUNNING OUTSIDE OF THE BUTTON BOX
@media screen and (min-width: 1100px) {
    h4 {
       font-size: 20px;
    }
  }
