@import '../../index.scss';

.navbar-container {
    position: sticky;
    // top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: wheat;
    font-size: 20px;
    height: 60px;
    z-index: 100;
    transition: background-color .3s ease-in;

    p {
      margin-top: 30px;
      font-size: 16px;
    }

    p:hover {
      color: $navbarHover;
    }
}

.dropdown-navbar-menu {
  position: absolute;
  top: 50px;
  left: 150px;
  background-color: rgba(38, 41, 53, 0.652);
  padding: 0px 15px 15px 15px;

  p:hover {
    color: $navbarHover;
  }
}

.navbar-container-change {
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 20px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.979);
  transition: background-color 1s ease-in;
  // transition: height .8s ease-in;
  z-index: 100;

  .dropdown-navbar-menu {
    p {
      color: $wheat-text;
    }
    
    p:hover {
      color: $navbarHover;
    }
  }

  p {
    margin-top: 15px;
    font-size: 16px;
    color: $primary;
    transition: margin-top .8s ease-in;
  }
  
}

.hamburger-navbar-container {
  position: absolute;
  top: 0;
  z-index: 10000;
}

.logo-text {
  font-size: 30px;
  font-weight: bold;
  margin-top: 15px;
  transition: margin-top .8s ease-in;
  text-shadow: rgba(255, 255, 255, 0.191) 4px 4px 5px;
  text-decoration: underline 2px $secondary;
  color: $white;
}

.logo-text-change {
  font-size: 30px;
  font-weight: bold;
  margin-top: 10px;
  transition: margin-top .8s ease-in;
  text-shadow: rgba(21, 20, 20, 0.261) 4px 4px 5px;
  text-decoration: underline 2px $secondary;
  color: $black;
}

.navbar-div {
  position: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 500px;
  height: 100%;
  left: 40%;
}

@media only screen and (max-width: 767px) {
    .navbar-container {
      font-size: 13px;
    }
  }