@import '../../../index.scss';

.option {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    max-height: 120px;
    min-width: 100px;
    border-radius: 3px;
    margin: 25px;
    background-color: $tileBackground;
    border: 1px solid $white;
    cursor: 'pointer';
    color: $wheat-text;
    font-size: 20px;

    div {
        position: absolute;
        text-align: left;
    }
}

.order-options-container {
    display: flex;
    justify-content: center;
}

.category-index {
    position: relative;
    right: 125px;
}