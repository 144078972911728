@import '../../index.scss';

    .card-sidebar-component-container {
        // position: relative;
        // width: 100%;
        // min-height: auto;
    }

    .card-component-container {
        position: relative;
        width: auto;
        min-height: 350px;
        margin: 150px 80px;
    }

    .card-sidebar-component {
        display: grid;
        grid-template-columns: 1fr auto;
        margin: 5%;
    }

    @media only screen and (max-width: 767px) {
      .card-component-container {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;        
        // min-height: 550px;
        width: 95%;
        margin: 150px auto;
      }

      .card-sidebar-component {
        display: grid;
        grid-template-columns: 1fr;
        margin: 5%;
        // margin-top: -150px;
    }
    }

    .card-component {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        border-radius: 20px;
        min-height: 400px;
        position: relative;
        width: 100%;
    }

    @media only screen and (max-width: 767px) {
        .card-component {
          width: 100%;
          display: initial;
          // CAUSING IMAGES TO MOVES RIGHT ON MOBILE
          // text-align: center;
        //   border-radius: 20px;
        //   min-height: 200px;  
          // top: -150px;
          // position: relative;

          h2 {
            width: 100%;
          }
        }

        .edit-search-results-mobile {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 55px;
          height: 30px;
          font-size: 15px;
          // right: 20px;
          // width: 75px;
          // height: 40px
        }

      }

    .navigation-button {
        position: absolute;
        width: 100px;
        height: 45px;
        margin: 5px;
        border: 1px solid $white;
        background-color: $primary;
        color: $white;
        border-radius: 3px;
        font-size: 18px;
    }

    .start-here-bubble-text {
        position: absolute;
        right: 0;
        bottom: 80px;
        border-radius: 50px;
        background-color: #fc5a4ba4;
        width: fit-content;
        padding: 20px;
        color: $white;
        filter: drop-shadow(5px 5px 5px rgb(49, 49, 49));
    }

    .down-arrow1 {
        position: absolute;
        bottom: 30px;
        right: 37px;
        width: 35px;
    }

    .down-arrow2 {
        position: absolute;
        bottom: 0px;
        right: 37px;
        width: 35px;
    }

    .navigation-button {
        bottom: -80px;
        border-radius: 3px;
    }

    .next-button {
        right: 0px;
    }

    .back-button {
        left: 0;
    }

    @media only screen and (max-width: 767px) {
        .next-button {
            position: absolute;
            right: 10px;
        }

        .back-button {
            left: 10px;
        }
      }

    button:hover {
        cursor: pointer;
        background-color: $translucent-white;
    }

.wrapper {
    position: relative;
    top: 35px;
    border: 1px solid $white;
    border-radius: 20px;
    width: 70%;
    margin: 0 auto;
    opacity: .6;
    transform: scale(.6);
  }
  
  .container {
    border-radius: 20px;
  }

  @media only screen and (max-width: 1125px) {
    .start-here-hide {
        display: none;
    }
}

@keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .bounce-animation {
    animation: bounce 1s infinite;
  }
