@import '../../../index.scss';

.price-container, .year-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .price-container, .year-container {
        display: flex;
        justify-content: center;
        width: 400px;
    }
}

.minMaxValue-container {
    position: relative;
    // width: 62vw;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    top: -90px;
    text-align: center;
    font-size: 22px;
    color: $white;
}

@media only screen and (max-width: 767px) {
    .minMaxValue-container {
      font-size: 18px;
      width: 100%;
    }
}

.input-range__slider {
    position: relative;
    padding: 1px;
    bottom: 8.5px;
    border: 1px solid $white;
    background-color: $slider-ball;
}

.input-range__label--value {
    display: none;
}

.input-range__track {
    height: 10px;
    border: 1px solid $white;
    bottom: 2.5px;
    background-color: $slider-background-dark;

}

.input-range__track--background {
    background-color: $white;
    height: 7px;
}

.input-range__label-container {
    color: $white;
    opacity: 0.7;
}

.dual-slider {
    position: relative;
    top: 50px;
    width: 85%;
}

