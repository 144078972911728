@import '../../../index.scss';

.homePage-container {
    background-color: $primary-dark;
}

.mobile-nav-div {
    position: fixed;
    background-color: rgba(22, 26, 34, 0.95);
    z-index: 1;
    border-radius: 5px;

    p {
        margin: 10px;
        padding: 5px;
    }

    p:hover {
        background-color: rgba(255, 255, 255, 0.109);
        padding: 5px;
        border-radius: 3px;
    }
}

.homePage-link-btn {
    position: relative;
    top: 40px;
    padding: 20px;
    color: white;
}

.homePage-link-btn:hover {
    transition: background-color .3s ease-in, color .3s ease-in;
    background-color: rgba(255, 255, 255, 0.764);
    color: black;
}

.homePage-link-btn1 {
    background-color: #fc5a4bc2;
    padding: 20px 12px;
}

@media only screen and (max-width: 767px) {
    .homePage-link-btn {
        position: relative;
        top: 0px;
        padding: 10px;
        color: white;
    }

    .homePage-link-btn1 {
        background-color: #fc5a4bc2;
        padding: 10px 3px;
    }

}

.homePage-link-btn2 {
    background-color: rgba(28, 55, 99, 0.803);
}

.homePage-link-btn3 {
    background-color: rgb(203, 168, 102);
}

.homePage-div1::before {    
    content: "";
    background-image: url('../../images/hoist.jpg');
    background-size: cover;
    position: absolute;
    height: 89vh;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.045;
}

.homePage-div1 {
    background-color: $primary-dark;
    height: 80vh;
    width: 100%;

    .homePage-slogan {
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: $white;

        h3 {
            position: relative;
            font-size: 50px;
            font-weight: 400;
            margin: 0;
            top: -30px;
        }

        .homePage-slogan-p {
            position: relative;
            top: 100px;
            width: 100%;
            display: inline-flex;
            justify-content: space-around;

            p {
                max-width: 250px;
            }
        }

    }

    @media screen and (max-width: 768px) {
        .homePage-slogan {
            position: relative;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            color: $white;
    
            h3 {
                position: relative;
                font-size: 35px;
                font-weight: 400;
                margin: 0;
                top: -30px;
            }
    
            .homePage-slogan-p {
                position: relative;
                top: 100px;
                width: 100%;
                display: inline-block;
                justify-content: space-around;
    
                p {
                    // max-width: 350px;
                    position: relative;
                    bottom: 20px;
                    margin: 0px auto;
                }
    
                div {
                    margin: 0px 0px 50px 0px;
                }
            }
    
        }
    }
        


    @media only screen and (max-width: 1065px) {
        
        .homePage-div4a, .homePage-div4b
        {
            display: block; 
            float: none; 
            width: 100%;
            top: 0;
        }
    }

}


.homePage-div2 {
    background-image: linear-gradient(to right top, #1d222d, #202735, #242c3e, #273147, #2b3650);
    height: 60vh;
    width: 100%;

    .div2-header {
        position: relative;
        top: 15%;
        margin-left: 5%;
        padding: 5%;
    }

    .div2-text {
        position: relative;
        margin: 30px;
        top: 5%;
    }
}

.homePage-div3::before {    
    content: "";
    background-image: url('../../images/car-background.jpg');
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.07;
}

.homePage-div3 {
    background-color: white;
    position: relative; 
    height: 100vh;
    width: 100%;

    .div3-header {
        width: 500px;
        position: relative;
        top: 20%;
        margin-left: 8%;
    }
    .div3-header-mobile {
        width: 300px;
        position: relative;
        top: 20%;
        margin-left: 1%;
    }

    .div3-text {
        width: 80%;
        position: relative;
        top: 25%;
        margin-left: 8%;
        
        span {
            display: block;
            margin: 3%;
        }
    }
}

.homePage-div4::before {    
    content: "";
    background-image: url('../../images/puzzle.jpg');
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: .025;
}

.homePage-div4 {
    position: relative; 
    // min-height: auto;
    height: fit-content;
    min-height: 70vh;
    padding: 0 0 100px 0;
    width: 100%;
    background-image: linear-gradient(to right top, #1d222d, #202735, #242c3e, #273147, #2b3650);
    overflow: auto;

    .homePage-div4a {
        width: 65%;
        float: left;
    }

    .homePage-div4b {
        position: relative;
        top: 75px;
        width: 35%;
        float: right;
    }

    .macbook-img {
        position: relative;
        top: 10%;
        left: -5%;
        width: 100%;
        min-width: 800px;
        filter: drop-shadow(13px 13px 13px rgb(0, 0, 0));
    }

    @media only screen and (max-width: 1065px) {
        .homePage-div4a, .homePage-div4b
        {
            display: block; 
            float: none; 
            width: 100%;
            top: 0;
        }
    }
}

.macbook-img-mobile {
    position: relative;
    top: 10%;
    left: -5%;
    width: 400px;
    filter: drop-shadow(13px 13px 13px rgb(0, 0, 0));
}

// .homePage-div5::before {
//     content: "";
//     // background-image: url('../../images/tools.jpg');
//     // background-size: cover;
//     position: absolute;
//     top: 0px;
//     right: 0px;
//     bottom: 0px;
//     left: 0px;
//     opacity: .04;
// }

.homePage-div5 {
    position: relative; 
    // min-height: auto;
    min-height: 60vh;
    width: 100%;
    // margin-bottom: 100px;
    background-color: $white;
    // background-image: linear-gradient(to right bottom, #ffffff, #f8f8fb, #f1f2f8, #e9ebf4, #e0e5f1);
    overflow: auto;

    p{
        color: black;
        font-size: 14px;
        font-weight: 500;
        }

    h4 {
        color: black;
        font-size: 35px; 
        font-weight: 400;
        
        span {
            text-decoration: solid underline $highlight-blue;
            text-underline-offset: 15px;
            text-decoration-thickness: 10px;
            display: inline-flex;
        }

    }

    .homePage-div5a {
        width: 40%;
        float: left;
    }
    .homePage-div5b {
        width: 60%;
        float: right;
        text-align: center;
    }
    .homePage-div5c {
        width: 50%;
        float: right;
    }
    .homePage-div5d {
        width: 50%;
        float: left;
        text-align: center;
    }
    .homePage-div5e {
        width: 40%;
        float: left;
    }
    .homePage-div5f {
        width: 60%;
        float: right;
        text-align: center;
    }

    .top-text-header {
        font-size: large;
        position: relative;
        top: 40px;
        margin-left: 50px;
    }

    span {
        display: block;
        margin: 10px;
    }

    .think-img {
        width: 400px;
        // filter: drop-shadow(8px 8px 8px rgb(0, 0, 0));
    }

    .think-img-mobile {
        width: 400px;
        // filter: drop-shadow(8px 8px 8px rgb(0, 0, 0));
    }

    .wallet-img {
        width: 300px;
        margin-top: 75px;
        filter: drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.474));

    }

    .wallet-img-mobile {
        width: 150px;
        border-radius: 50px 10px 50px 10px;
    }

    .map-img {
        width: 800px;
        margin-left: -100px;
        // filter: drop-shadow(2px 2px 2px rgb(0, 0, 0));
    }

    @media only screen and (max-width: 1050px) {
        .homePage-div5a, .homePage-div5b, .homePage-div5c, .homePage-div5d, .homePage-div5e, .homePage-div5f
        {
            display: block; 
            float: none; 
            width: 100%;
            top: 0;
        }
    }
}

.simple-highlight{
    background-color: $highlight-blue;
    padding: 2px;
    margin: 0;
    display: inline-flex;
    width: fit-content;
    border-radius: 8px 0px 8px 0px;
   }
