@import '../../../index.scss';

.slider {
    // position: fixed;
    // height: 200px;
    // color: $white;
    display: flex;
    justify-content: center;
    
    h3 {
        padding: 0;
        margin: 40px 0;
        text-align: center;
    }

    input {
        position: relative;
        width: 400px;
        top: 0%;
    }

}