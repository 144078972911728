@import '../../../index.scss';

.vehicle-card-container {
    // REMOVE ALL THESE WHEN IN THE HOVER MORE INFO SCREEN

    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    // grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
    margin: 0 10px;
}

.vehicle-card-container-more-info {
    grid-gap: 10px;
    margin: 0 30px;
}

// REMOVE
//     grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
// ADD
//      margin: 0 30px;


@media only screen and (max-width: 767px) {
.vehicle-card-container {
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    // grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
    margin: 75px 10px 20px 10px;
}
.vehicle-card-container-info {
    // display:grid;
    // grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    // grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));
    // grid-gap: 10px;
    margin: 40px 10px 20px 10px;
}
}

.vehicle-card {
    width: 220px;
    height: 160px;
    background-color: $cardBackground;
    border: 1px solid $white;
    box-shadow: 1px 1px 10px $blackLight;
    border-radius: 3px;
    color: $black;
    margin: 5px;

    div {
        margin: 2px;
        text-align: center;
    }

    h5 {
        margin: 0;
        color: $blackblack;
    }
    h1 {
        margin: 0;
        font-size: 35px;
        font-weight: 600;
        -webkit-text-fill-color: $black;
        -webkit-text-stroke: 2px;
    }

    @media only screen and (max-width: 767px) {
        h1 {
            font-size: 25px;
        }
        }

    .vehicle-ymm {
        font-weight: 700;
        background-color: rgb(60, 60, 82);
        color: white;
        padding: 10px;
        position: relative;
        left: 7px;
        bottom: 15px;
        border-radius: 3px;
        width: 70%;
        box-shadow: 1px 1px 5px rgb(0, 0, 0);
    }

    .vehicle-score {
        margin: 15% 0;
    }
}

@media only screen and (max-width: 767px) {
    .vehicle-card {
        width: 150px;
        height: 110px;
    }

    .vehicle-ymm {
        font-size: 14px;
    }
}

.vehicle-card:hover {
    transform: scale(1.18);
    box-shadow: 1px 1px 44px $black;
    cursor: pointer;
    transition: all .4s ease;
    background-color: $cardBackgrounHover;
    z-index: 1;

}

.vehicle-img {
    position: absolute;
    opacity: 1;
    width: 220px;
    height: 160px;   
    border-radius: 3px; 
    z-index: -1;
}


@media only screen and (max-width: 767px) {
    .vehicle-img {
        width: 150px;
        height: 110px;
    }
}

.vehicle-card:hover .vehicle-img {
    // opacity: 0.5;
    // transition: all 5s ease;
    z-index: 1;
}

.vehicle-info-hover {
    background-color: $hoverBackground;
    box-shadow: 2px 2px 8px $black;
    width: 250px;
    height: auto;
    padding: 3px;
    position: relative;
    left: 225px;
    bottom: 200px;
    z-index: 10;
    border-radius: 3px;
    border: 1px solid $white;

    h5 {
        position: relative;
        top: 5px;
        font-size: 18px;
    }

    ul {
        text-align: left;
        color: $blackblack;
        font-weight: 600;
    }
}

@media only screen and (max-width: 767px) {
    .vehicle-info-hover {
        position: initial;
        width: 120%;
        height: 280px;
        font-size: 10px;
    }

    ul {
        position: relative;
        padding: 8px;
        left: 5px;
    }
  }

.learn-more-btn {
    position: absolute;
    width: 120px;
    height: 30px;
    margin: auto 0;
    left: 50px;
    bottom: 5px;
    z-index: 1;
    background-color: $lightBlue;
    box-shadow: 2px 2px 8px $blackLight;
    border: none;
    border-radius: 30px;
    color: $white;
}

@media only screen and (max-width: 767px) {
    .learn-more-btn {
        width: 90px;
        height: 25px;
        left: 50px;
        bottom: 35px;
        font-size: 10px;
    }
  }

.learn-more-btn:hover {
    background-color: $darkBlue;
    color: $white;
    box-shadow: 2px 2px 10px $black;
    transform: scale(1.05);
    transition: all .4s ease;
}

.high-score {
    color: $darkGren;
    font-weight: 600;
}

.low-score {
    color: $darkRed;
    font-weight: 600;
}

.vehicle-info-container {
    width: 90vw;
}

.vehicle-info-page {
    position: relative;
    width: 100%;
    min-height: 80vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    color: $black;
    background-color: rgba(255, 255, 255, 0.738);
    top: -50px;
    box-shadow: 1px 1px 4px $black;

    // border: .5px solid $cardBackground;
    border-radius: 3px;

    .vehicle-make-model-header {
        position: relative;
        background-color: rgb(39, 39, 39);
        padding: 20px;
        color: $white;
        border-radius: 5px;
        bottom: 40px;
        left: 10px;
        box-shadow: 1px 1px 4px $black;

    }

    .vehicle-info-img {
        height: 140px;
        margin: 20px 0px 20px 30px;
        border-radius: 15px;
    }

    .vehicle-write-up-container {
        margin: 50px;
        font-size: 22px;
    }

    .vehicle-data-container {
        display: grid;
        // grid-template-columns: 1fr 1fr;
        grid-template-columns: 1fr;
        margin-top: 100px;
        margin-bottom: 30px;

        .vehicle-data-img {
            width: 30px;
        }

        .vehicle-data-table1{ 
            position: relative;
            left: 50px;
            bottom: 40px;
            ::first-letter {
                text-transform:capitalize;
            }
        td {
            text-align: left;
            padding: 2px;
        }
        }
    }
}

.no-match-msg {
    grid-column: 1/-1;
    grid-row: 2;
    position: relative;
    text-align: center;
    color: $white;
}

.no-results-update {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    margin-top: 20px;
    padding: 8px;
    border-radius: 3px;
    background-color: $darkBlue;
    color: $white;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 1px 1px 4px $black;
    border: none;
}

.close-btn {
    position: absolute;
    top: -30px;
    right: 0px;
    text-shadow: 1px 1px 25px $black;
    padding: 3px 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    // span for text representing the X to close the modal
    span {
        margin-bottom: 5px;
    }
}

.close-btn:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.vehicle-ratings-list {
    text-align: left;
    font-size: 22px;
    margin-left: 50px;
    // margin-left: 0px;
}

.update-function-btn {
    position: relative;
    width: 90%;
    z-index: 2;
    margin: 20px 10px 10px 10px;
}

.update-function-btn:hover {
    background-color: rgba(255, 255, 255, 0.872);
}

@media only screen and (max-width: 767px) {

    .vehicle-info-page {
        position: relative;
        width: 100%;
        min-height: 80vh;
        display: grid;
        grid-template-columns: 1fr;
        text-align: center;
        color: $white;
        border: .5px solid $cardBackground;
        border-radius: 3px;
    }

    body {
        font-size: 14px;
    }

    .vehicle-ratings-list {
        position: relative;
        left: 55px;
        width: 75%;
    }

    div {
        font-size: 14px;
    }

    li {
        font-size: 14px;
    }

    table {
        position: relative;
        margin-left: -25px;
        margin-top: -50px;
    }

    .vehicle-ratings-list {
        margin-left: 0px;
    }
    // .update-function-btn {
    //     display: none;
    // }
    }
