.shop-map-search {
    position: absolute;
    height: 330px;
    width: 190px;
    top: 18%;
    left: 1%;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 10px;
    z-index: 10;
    border-radius: 3px;

    .shop-map-search-input {
        border: 1px solid transparent;
        width: 240px;
        height: 35px;
        padding: 0 12px;
        border-radius: 3px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        font-size: 18px;
        outline: none;
        text-overflow: ellipses;
        width: 85%;
        margin-bottom: 25px;
        }

    .shop-map-checkboxes {

        margin-top: -100px;
        z-index: 10000;

        input {
            margin: 5px;
        }

        label {
            background-color: white;
            color: black;
        }

    }

}

@media only screen and (max-width: 767px) {
    .shop-map-search {
        position: absolute;
        height: fit-content;
        width: 130px;
        top: 25%;
        left: 1%;
        background-color: white;
        display: flex;
        flex-direction: column;
        padding: 10px;
        z-index: 10;
        border-radius: 3px;
    
        .shop-map-search-input {
            border: 1px solid transparent;
            width: 240px;
            height: 35px;
            padding: 0 12px;
            border-radius: 3px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
            font-size: 18px;
            outline: none;
            text-overflow: ellipses;
            width: 85%;
            margin-bottom: 25px;
            }
    
        .shop-map-checkboxes {
            font-size: 10px;
            margin-top: -100px;
            z-index: 10000;
    
            input {
                margin: 5px;
            }
    
            label {
                background-color: white;
                color: black;
            }
    
        }
    
    }
}

.info-window-container {
    min-height: 50px;
    min-width: fit-content;

    .info-window-business-name {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 5px;
    }
}

.number-of-ratings {
    position: relative;
    bottom: 3px;
    left: 3px;
    font-size: 10px;
}

.user-location-search {
    position: relative;
    margin: auto auto;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgba(232, 232, 232, 0.849);
    border-radius: 10px;
    width: 400px;
    height: 200px;
    text-align: center;
    z-index: 1;
    top: 30%;

    p {
        position: relative;
        top: 10px;
        font-size: 20px;
    }

    input {
        position: relative;
        width: 250px;
        height: 35px;
        font-size: 18px;
        top: 50px;
        border-bottom: 2px solid rgba(218, 218, 218, 0.949);
    }

    .user-location-search-button {
        position: absolute;
        bottom: 10px;
    }
}

.hideThis {
    opacity: 0;
}

.tooltip-container {
    position: absolute;
    top: 80px;
    right: 100px;    
    background-color: rgba(255, 255, 255, 0.777);
    border: 2px solid white;
    width: 270px;
    height: 130px;
    z-index: 10;
    border-radius: 3px;
    text-align: center;

    .tooltip-header {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
    }

    .tooltip-body {
        font-size: 17px;
        margin-top: 10px;
        line-height: 20px,
    }

}

@media only screen and (max-width: 767px) {
    .tooltip-container {
        position: absolute;
        top: 60px;
        right: 10px;    
        background-color: rgba(255, 255, 255, 0.777);
        border: 1px solid white;
        width: 150px;
        height: fit-content;
        z-index: 10;
        border-radius: 3px;
        text-align: center;
        padding: 5px;
    
        .tooltip-header {
            font-size: 14px;
            font-weight: bold;
            margin-top: 0px;
        }
    
        .tooltip-body {
            font-size: 12px;
            margin-top: 10px;
            line-height: 15px;
        }
    
    }
  }

.diy-instructions-container {
    margin: 150px 10%;

    img {
        margin: 30px;
        width: 70%;
    }
}

.diy-close-btn {
    position: absolute;
    cursor: pointer;
    top: 100px;
    right: 5%;
    font-size: 22px;
    color: white;
    text-shadow: 2px 2px 2px black;
}
