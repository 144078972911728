@import '../../../index.scss';

.subscribe-message {
    position: fixed;
    width: 500px;
    height: 300px;
    color: #fc5a4b;
    font-size: 64px;
    font-weight: 500;
    top: 20%;
    left: 30%;
    z-index: 100;
    border-radius: 20px;
    text-align: center;
    background:linear-gradient(45deg, #EEEEEE, #FAF8F9, #FFFFFF);
    
    p {
        margin-top: 55px;
    }
}

.confetti-div {
    position: fixed;
    width: 100vh;
    height: 100vw;
}

@media only screen and (max-width: 767px) {
    .grid-div1 {
      display: none;
    }
  }

  @media only screen and (max-width: 767px) {
    .grid-div5 {
        display: none;
    }

    .subscribe-container {
        display: none;
    }

  }
  

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .grid-div1 {
        position: relative;
        height: 550px;

        .people-thinking-img {
            position: relative;
            left: 120px;
            top: 230px;
            filter: drop-shadow(5px 5px 5px rgba(34, 34, 34, 0.571));
        }
    }

    .grid-div2 {
        position: relative;
        color: wheat;
        height: 70vh;
        width: 100%;
        z-index: 1;

        .grid-div2-container {
            position: relative;
            top: 200px;
        }
        
        h1 {
            font-size: 60px;
            margin: 5px 15px 0px 140px;
            filter: drop-shadow(3px 3px 3px rgb(0, 0, 0));
            width: 300px;
        }

        h3 {
            margin: 35px 15px 0px 150px;
            color: white;
        }

        @media only screen and (max-width: 767px) {
            h1 {
                margin: 5px 15px 0px 60px;
            }

            h3 {
                margin: 35px 15px 0px 135px;
                font-size: 14px;
            }
          }

        button {
            position: relative;
            background-color: #fc5b4b;
            border: 1px solid wheat;
            width: 210px;
            height: 50px;
            margin: 50px 0px 0px 150px;
            color: white;
            font-size: 20px;
        }

        button:hover {
            background-color: #1b2d4b;
            cursor: pointer;
        }
    }

    .think-bubble-container {
        min-width: 110px;
        img {
            margin: 10px
        }

    }

    .think-bubble1 {
        position: absolute;
        transform: rotate(220deg);
        top: 260px;
        left: 140px;
        }
    .think-bubble2 {
        position: absolute;
        transform: rotate(270deg);
        top: 240px;
        left: 230px;
        }
    .think-bubble3 {
        position: absolute;
        transform: rotate(270deg);
        top: 220px;
        left: 400px;
        }
    .think-bubble4 {
        position: absolute;
        transform: rotate(330deg);
        top: 250px;
        left: 480px;
        }

    .cloud1 {
        transform: rotate(-25deg);
        position: absolute;
        top: 150px;
        opacity: .7;
        animation: fadeIn 10s 1s 1, fadeOut 7s 11s 1;

        img {
            position: absolute;
            filter: drop-shadow(2px 2px 2px rgb(0, 0, 0));
        }

        h2 {
            position: absolute;
            top: 85px;
            left: 15px;
            color: #fc5b4b;;
        }
    }
    .cloud2 {
        transform: rotate(-5deg);
        position: absolute;
        top: 130px;
        left: 225px;
        opacity: .4;
        animation: fadeIn 10s 1s 1, fadeOut 7s 11s 1;

        img {
            position: absolute;
            filter: drop-shadow(2px 2px 2px rgb(0, 0, 0));
        }

        h2 {
            position: absolute;
            top: 45px;
            left: 15px;
            color: #fc5b4b;
        }
    }
    .cloud3 {
        transform: rotate(-5deg);
        position: absolute;
        top: 115px;
        left: 395px;
        opacity: .7;
        animation: fadeIn 10s 1s 1, fadeOut 7s 11s 1;

        img {
            position: absolute;
            filter: drop-shadow(2px 2px 2px rgb(0, 0, 0));
        }

        h2 {
            position: absolute;
            top: 45px;
            left: 20px;
            color: #fc5b4b;;
        }
    }
    .cloud4 {
        transform: rotate(10deg);
        position: absolute;
        top: 140px;
        left: 560px;
        opacity: .3;
        animation: fadeIn 10s 1s 1, fadeOut 7s 11s 1;

        img {
            position: absolute;
            filter: drop-shadow(2px 2px 2px rgb(0, 0, 0));
        }

        h2 {
            position: absolute;
            top: 45px;
            left: 20px;
            color: #fc5b4b;;
        }
    }

    @keyframes fadeIn {
        0% {
          filter: contrast(1);
        }
        33% {
            filter: contrast(.66);
        }
        66% {
            filter: contrast(.33);
        }
        100% {
            filter: contrast(0);
        }
      }
    @keyframes fadeOut {
        0% {
          filter: contrast(0);
        }
        33% {
            filter: contrast(.33);
        }
        66% {
            filter: contrast(.66);
        }
        100% {
            filter: contrast(1);
        }
      }

      @keyframes smokeDelayIn {
        0% {
            opacity: 0;
        }
        33% {
            opacity: .4;
        }
        66% {
            opacity: .7;
        }
        100% {
            opacity: 1;
        }
      }
    @keyframes smokeDelayOut {
        0% {
            opacity: 1;
        }
        33% {
            opacity: .7;
        }
        66% {
            opacity: .4;
        }
        100% {
            opacity: 0;
        }
      }

      .grid-div3 {
          grid-column-start: 1;
          grid-column-end: 3;
          height: 500px;
          width: 100%;
          margin-top: 100px;
          background-color: wheat;

          p {
              font-size: 40px;
              font-weight: 700;
              margin: 120px 10% 50px 10%;
              text-align: center;
          }

          h1 {
              font-size: 50px;
              font-weight: 900;
          }

          h1:hover {
            transform: scale(1.1);
          }

          .grid-div3-banner {
              position: relative;
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
              font-weight: 800;
              text-align: center;

              .data-img {
                  position: absolute;
                  width: 50px;
                  margin-left: 15px;
                  margin-top: -4px;
              }
              
              div {
                  width: 20%;
              }

              h1 {
                    font-size: 50px;
              }

              @media only screen and (max-width: 767px) {
                h1 {
                  font-size: 26px;
                }
              }

              h4 {
                  font-size: 16px;
              }

              span {
                  position: relative;
                  font-size: 15px;
                  bottom: 50px;
              }
          }
      }

      .how-it-works-container {
        grid-column-start: 1;
        grid-column-end: 3;
        background-color: white;
        height: auto;
        min-height: 200px;
          
        h1 {
            font-size: 54px;
            font-weight: 400;
            background-color: white;
            text-align: center;
        }

        .how-it-works-text {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;

            span {
                max-width: 25%;
                text-align: center;
            }
        }

      }

      .grid-div4 {
        grid-column-start: 1;
        grid-column-end: 3;
        background-color: white;
        height: auto;
        min-height: auto;

        h1 {
            font-size: 34px;
            font-weight: 400;
            background-color: white;
            text-align: center;
            margin: 100px 0px -50px;
        }

        .device-image-collage {
            position: relative;
            top: 40px;
            width: fit-content;
            margin: 0 auto;

            .macbook-website-img {
                position: relative;
                margin: auto auto;
                width: 85%;
                left: 70px;
                z-index: 2;
                filter: drop-shadow(5px 5px 5px #222);
            }
    
            .ipad-website-img {
                position: absolute;
                width: 25%;
                left: 80px;
                margin-top: 70px;
                filter: drop-shadow(2px 2px 5px #222);

            }
    
            .iphone-website-img {
                position: absolute;
                width: 12%;
                z-index: 3;
                left: 90px;
                bottom: 110px;
                // margin-top: 320px;
                filter: drop-shadow(5px 5px 5px #222);
            }

        }
      }

      .grid-div5 {
        grid-column-start: 1;
        grid-column-end: 3;
        background-color: rgb(236, 235, 235);
        height: auto;
        min-height: 700px;

        h1 {
            font-size: 54px;
            font-weight: 400;
            margin: 50px 150px;
            text-align: center;
        }
        
        .grid-div5-box-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            img {
                position: relative;
                bottom: 30px;
                border-radius: 40%;
                height: 100px;
                transform: scale(1.2);
                border: 3px solid white;
                // margin-top: -30px;
            }

            span {
                background-color: white;
                box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.43);
                border-radius: 20px;
                text-align: center;
                margin: 20px;
                height: fit-content;
                text-align: center;
                max-width: 360px;
            }

            p {
                margin: 20px;
                font-size: 20px;
            }

            h3 {
                float: right;
                margin: 80px 40px 0px 0px;
            }

            h5 {
                margin-top: 50px;
                font-size: 18px;
                font-style: italic;
            }
            
        }
    }

      .grid-div6 {

        grid-column-start: 1;
        grid-column-end: 3;
        background-color: white;
        height: auto;

        .car-under-cover {
            width: 60%;
            margin-top: -90px;
            z-index: -10000;
        }
        
        h1 {
            grid-column-start: 1;
            grid-column-end: 2;
            font-size: 85px;
            margin: 50px;
        }

        h3 {
            grid-column-start: 1;
            grid-column-end: 2;
            font-size: 50px;
            margin-left: 50px;
        }

        .five-minute-text {
            position: relative;
            float: right;
            font-weight: 400;
            margin-right: 200px;
            bottom: 50px;
        }
    }

    .grid-div7 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-column-start: 1;
        grid-column-end: 3;
        background-color: white;
        justify-content: space-evenly;

        .top-ranked-container {
            width: 100%;
            text-align: center;
        }

        span {
            background-color: white;
            height: 200px;
            width: 200px;
            margin: 20px;
            border-radius: 20px;
            filter: drop-shadow(5px 5px 5px rgba(34, 34, 34, 0.571));
            text-align: center;

            img {
                width: 95%;
                position: absolute;
                text-align: center;
                right: 0px;
                opacity: .3;
            }

            div {
                position: relative;
                top: 30px;
            }

            h5 {
                margin: 0;
            }

            h1 {
                margin: 0;
            }
        }
    }
    
    .gradient-change {
        grid-column-start: 1;
        grid-column-end: 3;
        height: 200px;
        background: rgb(245,222,179);
        background: linear-gradient(180deg, rgba(245,222,179,1) 0%, rgba(245,222,179,1) 36%, rgba(250,240,220,1) 54%, rgba(255,255,255,1) 100%);
    }

    .big-quote {
        font-size: 40px;
        font-weight: 900;
        color: #fc5b4b;;
        margin: 2px;
    }

}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }

@media only screen and (max-width: 767px) {
.grid-container {
    display: grid;
    grid-template-columns: 1fr;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }
  }
