.vehicle-search-container {
  margin: 50px 0px 100px 0px;
  width: 100%;

button {
  border: none;
  padding: 5px;
  margin: 5px 15px 15px 15px;  
}

button:hover {
  background-color: rgba(255, 255, 255, 0.801);
}

.vehicle-database-table-container {
  overflow-x: auto;
  white-space: nowrap;

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: 5px;

    tr:hover {
      background-color: rgba(251, 240, 220, 0.822);
    }
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }
  
  tr:nth-child(odd) {
    background-color: #eeeded;
  }
}

  .vehicle-db-search {
    color: white;

    .vehicle-db-tool-tip {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.582);
      width: fit-content;
      height: fit-content;
      right: 100px;
      top: 100px;
      border: 2px solid rgba(255, 255, 255, 0.805);
      border-radius: 3px;
      color: black;
      padding: 15px;

      .vehicle-db-close-btn {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: white;
        width: fit-content;
        height: fit-content;
        border-radius: 50%;
        padding: 3px 8px;
        cursor: pointer;
      }

      ul {
        list-style-type: none;
        margin: 5px;
        padding: 0;
      }

      h3 {
        text-align: center;
        margin: 0;
      }
    }

    @media only screen and (max-width: 767px) {

    .vehicle-db-tool-tip {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.582);
      width: fit-content;
      height: fit-content;
      right: 5px;
      top: 5px;
      border: 1px solid rgba(255, 255, 255, 0.805);
      border-radius: 3px;
      color: black;
      padding: 3px;

      .vehicle-db-close-btn {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: white;
        width: fit-content;
        height: fit-content;
        border-radius: 50%;
        padding: 3px 8px;
        cursor: pointer;
      }

      ul {
        list-style-type: none;
        margin: 5px;
        padding: 0;
      }

      h3 {
        text-align: center;
        margin: 0;
      }
    }
  }

    p {
      color:wheat;
      font-weight: 500;
      margin: 3px;
      font-size: 10px;
    }

    input {
      width: 80px;
      margin: 3px;
      height: 22px;
      border: none;
      border-radius: 2px;
    }

    select {
      margin: 3px;
      height: 25px;
      min-width: 125px;
      border: none;
      border-radius: 2px;
    }
}

  .no-results-msg {
    position: absolute;
    color: white;
    font-size: 22px;
    display: grid;
    margin: 25px 0px 100px 0px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}