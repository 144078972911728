@import '../../index.scss';

#Rain {
	position: absolute;
	top: 210px;
	left: 60px;
	overflow: hidden;
	width: 590px;
	height: 500px;
    border-radius: 190px 150px 0px 0px;
    z-index: 100;
}

.drop {
	background: $rain-color;
    opacity: 0;
	width: 2px;
	height: 22px;
	position: absolute;
	animation: falling .8s  linear  infinite, rainDelayIn 14s 2s linear 1, rainDelayOut 2s 14s linear 1;
}

@media (max-width: 600px) {
	#Rain {
		height: 105vh;
	}
}

@keyframes  falling {
	0% {
		transform: translate(0, 0)
	}
	100% {
		transform: translate(0, 1250px)
	}
}

@keyframes rainDelayIn {
    0% {
        opacity: 0;
    }
    33% {
        opacity: .4;
    }
    66% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
  }
@keyframes rainDelayOut {
    0% {
        opacity: 1;
    }
    33% {
        opacity: .7;
    }
    66% {
        opacity: .4;
    }
    100% {
        opacity: 0;
    }
  }